<template>
  <div class="px-5 py-5">
    <app-create-type @created="getMaterialTypes" />
    <v-data-table :dark="$store.state.isDarkMode"
    :headers="headers"
    :loading="loading"
    :items="items"
    class="elevation-1 mt-5"
    hide-default-footer
    >
    <template v-slot:item.actions="{ item }">
        <deleteOne :id="item.id" @record-deleted="getMaterialTypes" />
        <app-create-type :id="item.id" :isNew="false" @created="getMaterialTypes" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import create from "./create.vue";
import deleteOne from "./deleteOne.vue";
import i18n from "../../../../i18n";

export default {
  components: {
    appCreateType: create,
    deleteOne,
  },
  data() {
    return {
      headers: [
        { text: i18n.t('Material Type Name'), value: "name" },
        { text: i18n.t('Value'), value: "value" },
        { text: "", value: "actions" },
      ],
      items: [],
      loading: false,
    };
  },
  methods: {
    async getMaterialTypes() {
      this.items = [];
      this.loading = true;
      const res = await axios.get("/inventories/material-types/index");
      const data = res.data.data;
      this.items = data;
      
      this.loading = false;
    },
  },
  created() {
    this.getMaterialTypes();
  },
};
</script>

<style></style>
