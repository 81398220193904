<template>
  <v-dialog v-model="dialog" width="600" open-on-click @input="fetch">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isNew" color="green" dark class="mb-2" v-bind="attrs" v-on="on">{{ $t('Add Material Type') }}</v-btn>
      <v-btn v-else v-bind="attrs" v-on="on" color="blue" icon>
        <v-icon class="ml-1">mdi-pen</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2>{{ $t('Add Material Type') }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('Type')" outlined dense color="#757575" v-model="payload.name">
        </v-text-field>
        <h3>{{ $t('Value') }}</h3>
        <v-col>
          <table>
            <tr v-for="(el, ix) in payload.values" :key="ix">
              <td>
                <v-text-field @keydown.enter="addNewValue" full-width type="text" dense hide-details flat color="#757575"
                  v-model="el.name"></v-text-field>
              </td>
              <td>
                <v-icon @click="deleteRowV(ix)" color="red"
                  style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
              </td>
            </tr>
          </table>
        </v-col>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="create" depressed :disabled="!payload.values" color="blue-grey" class="white--text"
          :loading="loading">{{ isNew ? $t('add') : $t('edit') }} </v-btn>
        <v-btn depressed color="primary" @click="dialog = false">{{
          $t("cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      payload: {
        name: null,
        values: [],
      },
      types: [
        {
          label: "أحجام",
          value: "sizes",
        },
        {
          label: "ألوان",
          value: "colors",
        },
      ],
    };
  },
  created() {
    if (this.isNew) {
      this.addNewValue(false);
    }
  },
  methods: {
    deleteRowV(index) {
      if (this.payload.values.length == 1) return;
      this.payload.values.splice(index, 1);
    },
    addNewValue(focus = true) {
      this.payload.values.push({
        name: null,


      });
      if (focus) {
        setTimeout(() => {
          this.ToNextInput();
        }, 0);
      }
    },
    ToNextInput() {
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("table input:not([type=hidden])")
      );
      const currInput = document.activeElement;
      const currInputIndex = inputs.indexOf(currInput);
      const nextinputIndex = currInputIndex + 1;
      const input = inputs[nextinputIndex];
      input.focus();
    },
    async create() {
      try {
        this.loading = true;
        if (this.isNew) {
          await axios.post("/inventories/material-types", this.payload);
        } else {
          await axios.put(
            `/inventories/material-types/${this.id}`,
            this.payload
          );
        }
        this.dialog = false;
        this.$emit("created");
      } finally {
        this.loading = false;
      }
    },
    async fetch() {
      if (!this.dialog) {
        this.payload.name = "";
        this.payload.values = null;
      }
      if (this.dialog && !this.isNew) {
        this.loading = true;
        const res = await axios.get(`/inventories/material-types/${this.id}`);
        const temp = res.data.data;
        console.log(temp);
        this.payload.name = temp.name;
        this.payload.values = temp.values;
        this.loading = false;

      }
    },
  },
};
</script>

<style scoped lang="scss">
table {
  border-collapse: collapse;
  table-layout: fixed;
}

.tableId {

  width: 2500px;

}

td,
th {
  padding: 5px 15px;
}

table,
th,
td {
  border: 1px solid #000;
}

.columnSelector:hover {
  border-right: 2px solid blue;
}
</style>

